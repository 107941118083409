//main: style.scss

/* MIXINS che porto nelle statiche */

// max-width x paragrafi: 576px

  @mixin prose {
    // @include prose

    max-width: 576px; // up sm

  }

  /// stile x accessibilità

  @mixin linkFocusAcc {
  // @include linkFocusAcc

    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }

  }

  @mixin buttonFocusAcc {
    // @include buttonFocusAcc

    &:focus {
      text-decoration: underline!important;
    }

  }


//// PSEUDO classi A



  @mixin underlineTransition { // necessario span inline (! duble line?)

    position: relative;

    &:after {

      content:' ';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 1px;
      background: #fff;
      -webkit-transition: .35s;
      transition: .35s;

    }

  }

  @mixin underlineTransitionHover { // con il readmore parte dal div contenitore

    &:after {

      width: 100%;

    }

  }

  @mixin setLinkSelectorStyle {
    // @include setLinkSelectorStyle {color:gold;}

    &:link, &:active, &:visited {
      @content;
    }

  }

  @mixin setLinkHoverSelectorStyle {
    // @include setLinkHoverSelectorStyle {color:gold;}

    &:focus, &:hover {
      @content;
    }

  }

  @mixin linkNoUnderline {
    //@include linkNoUnderline;

    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }

// EFFETTO HOVER 2

    // span.underline {
    //
    //   border-bottom: 1px solid transparent;
    //   transition: all ease-in-out 1s;
    //   @extend .p-0;
    //   @extend .m-0;
    //
    //   &:hover {
    //     color: red;
    //     border-color: red;
    //   }
    //
    // }

  }

  @mixin linkUnderline {
    //@include linkUnderline;

    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }

  }


  @mixin linkBlack {

    @extend .normal;
    @include linkNoUnderline;
    color: #000;

    &:hover, &:focus {
      color:#000;
      text-decoration: underline;
      background-color: transparent;
    }

  }

  @mixin linkRed {

    @extend .bold;
    @include linkUnderline;
    color: $rosso;

    &:hover, &:focus {
      color:#fff;
      // text-decoration: underline;
      background-color: $rosso;
    }

  }


// BUTTON

.btn-secondary {

  @extend .normal;
  color: $rosso;
  border: 1px solid $rosso!important;

  @include setLinkSelectorStyle {
    color: $rosso;
  }

  @include setLinkHoverSelectorStyle {
    color: $rosso;
    text-decoration: none;
  }

}


.btn-secondary-full {

  @extend .normal;
  color: $white;
  border: 1px solid $rosso!important;
  background-color: $rosso;;

  @include setLinkSelectorStyle {
    color: $white;
  }

  @include setLinkHoverSelectorStyle {
    color: $white;
    text-decoration: none;
  }

}


// animazione

  %transition-standard {

    -webkit-transition: all 0.6s linear;
    -moz-transition: all 0.6s linear;
    -ms-transition: all 0.6s linear;
    -o-transition: all 0.6s linear;
    transition: all 0.6s linear;

  }

  %transition-quick {

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;

  }

//// stili testo

  .light {
    font-weight:$font-weight-light!important;
  }
  .normal {
    font-weight:$font-weight-normal!important;
  }
  .semibold {
    font-weight:$font-weight-semibold!important;
  }
  .bold {
    font-weight:$font-weight-bold!important;
  }

  %break-word {
    word-break: break-word;
  }

// focus state

  %focus-none {

    @extend %box-shadow-focus-none;
    outline: auto 0px -webkit-focus-ring-color;
    // border-radius:0!important;

  }

  %focus-visible {

    @extend %box-shadow-focus;
    outline: auto 0px -webkit-focus-ring-color;
    // border-radius:0!important;

  }

// spazi e allineamenti speciali layout una colonna

@mixin layout-no-sidebars { // centrato

  @extend .col-12;
  @extend .col-lg-8;
  @extend .offset-lg-2;
  @extend .no-gutters-col;

}

@mixin layout-no-sidebars-left {

  @extend .col-lg-12;
  @extend .offset-lg-0;

}


// gestione spazi col

  .no-gutters-col { // è accompagnato da .no-gutters su row
    //usage @extend .no-gutters-col
      @extend .px-0;
  }

  %no-gutters-col { // x paragraph e primi livelli di container
    // usage @extend %no-gutters-col
    @extend .px-md-0;
  }

  %no-gutters-col-md-down { // x i field, imgdx e sx

    @include media-breakpoint-down(md) {

      padding-left: 0;
      padding-right: 0;

    }

  }

  %no-style-col {

    @extend .col-md-12;
    @extend .offset-md-0;
    @extend %no-gutters-col;
    width: 100%; /*era auto, no mobile! lasciato solo su field--name-field__sistemapiemonte */
    /*position: inherit; proviamo a toglierlo, sovrascriver position:relative */

  }

  %col-md-offset-center { // magari toglierlo e usare solo  layout-no-sidebars

    @extend .col-12;
    @extend .col-lg-8;
    @extend .offset-lg-2;

  }


// problemi di extend...
// @extend .row; //se da fastidio usare sotto
%row-hack {
  @extend .d-flex;
  @extend .flex-wrap;
  margin-right: -$md-gutter / 2!important;
  margin-left: -$md-gutter / 2!important;
}


// problemi di extend...
// @extend .container; //se da fastidio usare sotto
%container-hack {
  @include make-container();
  @include make-container-max-widths();

  // @include media-breakpoint-down(lg) {

  //   // sui dispositivi mobili molto stretti, tolgo il max width
  //   max-width: none !important;

  // }

}

//reset container

  %reset-container {
    @extend .m-0;
    @extend .p-0;
    max-width:none!important;
  }

//reset paddin e margin small

  .reset-all-space.reset-all-space {
    @extend .m-0;
    @extend .p-0;
  }

// generic space

  %space-bottom-base-content-reset { //0
    // usage @extend %space-bottom-base-content-reset

    @extend .mb-0;
    @extend .mb-md-0;

  }

  %space-top-base-content-padding { //60 ex 90
    // usage @extend %space-top-base-content;

    @extend .pt-6;
    @extend .pt-md-7;

  }

  %space-bottom-base-content-padding { //60 ex 90
    // usage @extend %space-top-base-content;

    @extend .pb-6;
    @extend .pb-md-7;

  }

  %space-top-base-content { //60 ex 90
    // usage @extend %space-top-base-content;

    @extend .mt-6;
    @extend .mt-md-7;

  }

  %space-bottom-base-content { //60 ex 90
    // usage @extend %space-bottom-base-content;

    @extend .mb-6;
    @extend .mb-md-7;

  }

  %space-top-small-content { //30 ex 60
    // usage @extend %space-top-small-content;

    @extend .mt-5;
    @extend .mt-md-6;

  }

  %space-bottom-small-content { //30 ex 60
    // usage @extend %space-bottom-small-content;

    @extend .mb-5;
    @extend .mb-md-6;

  }

  %space-padding-bottom-small-content { //30 ex 60
    //  quando c'é un bordo e necessiat di spazio sopra uguale al margin
    // usage @extend %space-padding-bottom-small-content;

    @extend .pb-5;
    @extend .pb-md-6;

  }

  %space-bottom-minimal-content { // 16px (spazio tra paragrafi base)

    @extend .mb-3;
    @extend .mb-md-3;

  }

  %space-bottom-base-title { //30px ex 60
    // usage @extend %space-bottom-base-title

    @extend .mb-5;
    @extend .mb-md-6;

  }

  %space-top-base-title { // 16px ex 30px only h1 with up 'conten type'
    // usage @extend %space-top-base-title

    @extend .mt-3;
    @extend .mt-md-3;

  }

  %space-bottom-small-title { // 16px ex 30px only h1 with down 'target/rivolto a'
    // usage @extend %space-bottom-small-title

    // @extend .mb-5;
    // @extend .mb-md-6;
    @extend .mb-3;
    @extend .mb-md-3;

  }

  %space-top-base-title-text-formatted { //14px only h2 e h3 inseriti da editor
    // usage @extend %space-top-base-title-text-formatted 30px-16px =14px

    @extend .pt-10;
    @extend .pt-md-11;

  }

  %title-base-space {

    h2, h3 {

      @extend .mb-6;

    }

  }

// Link

  %link-full-readmore { // definito su A

    position:absolute;
    z-index: 2;
    left: 0;
    top:0;
    @extend .d-block;
    width:100%;
    height:100%;

    &:hover, &:focus {

      background-color: transparent;

    }

  }

  %link-full-notxt {
    text-indent: -999999999999999999999px;
    overflow: hidden;
    content:" ";
  }

  %linkOut-noIcon {
    // usage @extend %linkOut-noIcon

    a[target="_blank"] {

      &:after {

        content:"";
        @extend .pl-0;

      }

    }

  }

// BACKGROUND //

  .bkg-none {

    background-color: transparent;

  }

/* BOX SHADOW */
// @include box-shadow-my(0, 0, 10px, 0, rgba(155,162,166, 0.5), true);

  @mixin box-shadow-my($top, $left, $blur, $blur-dis, $color, $inset: false) {
    @if $inset {
      -webkit-box-shadow:inset $top $left $blur $blur-dis $color!important;
      -moz-box-shadow:inset $top $left $blur $blur-dis $color!important;
      box-shadow:inset $top $left $blur $blur-dis $color!important;
    } @else {
      -webkit-box-shadow: $top $left $blur $blur-dis $color!important;
      -moz-box-shadow: $top $left $blur $blur-dis $color!important;
      box-shadow: $top $left $blur $blur-dis $color!important;
    }
  }

    %box-shadow-focus-none {
      // usage @extend %box-shadow-focus-none
        @include box-shadow-my(0, 0, 0, 0, $keyFocus, true);
    }

    %box-shadow-focus {
      // usage @extend %box-shadow-focus
        @include box-shadow-my(0, 0, 0, 3px, $keyFocus, true);
    }

// IMAGE GENERIC //

  // .testing3 {
  //     @include backgroundShorthandWithExternalVar('filename.png', );
  //     @include backgroundShorthandWithExternalVar('logo_RP_footer.png',$position:15px 0,$repeat: no-repeat, $color:transparent);
  // }

  $imagedir:'../images/'; // define the base path before the mixin

  @mixin backgroundShorthandWithExternalVar($filename,$position:center center,$repeat: no-repeat, $color: transparent) {
      background: $color url(#{$imagedir}#{$filename}) $repeat $position;
  }

// larghezza div che contine im, video ecc...

  %area-fit-media {

    @extend .embed-responsive;
    @extend .mx-auto;
    @include media-breakpoint-up(lg) {
      width:74%!important;
    }

  }

// immagine tag Figure (x ora solo su PI)

  %area-im-fig-full-size { // per i field che hanno area-fit-media

    @extend .d-flex;
    @extend .align-items-center;

    width:100%;

  }

  %area-im-fig { // si lascia per field che sono griglia per cui è necessario definire l'oggetto stesso

    @extend .d-flex;
    @extend .align-items-center;

    width:100%;
    @include media-breakpoint-up(lg) {

      width:74%!important;
      margin: auto;

    }

  }

  %style-caption-fig {

    width: 100%;

  }

  %style-im-fig {

    width:100%!important;

  }

// immagine principale (no tag Figure)

  %area-im {

    width:100%;
    height:auto;
    @extend .position-relative;
    @extend .text-center;

  }

  %style-im-full-size { // per i field che hanno area-fit-media

    width:100%;
    height:auto;

  }

  %style-im { // si lascia per field che sono griglia per cui è necessario definire l'oggetto stesso

    width:100%;
    height:auto;

    @include media-breakpoint-up(lg) {

      width:74%;

    }

  }

  %imgFitCard {

    img {

      width:100%;
      height:auto;
      @extend .mb-0;

    }

  }

// icon center :right

  %icon-center-right { // sull'elemento che contiene icona
      // usage @extend %icon-center-right

    @extend .position-relative;
    @extend .d-flex;
    @extend .align-items-center;

    &:after {

      @extend .position-absolute;
      right: 0;

    }

  }


// spazi contenuti liberi editor (text-formatted e corpo contatto persona )

  %space-content-corpo {

    h2, h3 {

      // @extend %space-top-base-title-text-formatted;
      @extend .mb-3;

      strong {

        @extend .font-weight-normal;

      }

    }
    // @extend %title-base-space;

    ul {

      @extend %ULdefintion;

    }

    ol {

      @extend %OLdefinition;

      > li {

        ul {

          @extend %ULdefintion;

        }

        ol {

          @extend %OLdefinition;

        }

      }

    }

  }

// definizione UL e OL

  %ULdefintion {
      // usage @extend %ULdefintion

    margin-left: 1em!important; /* width LI OL*/

    &.ul-js { // messo per evitare che venga preso nelle pagine statice senza js

      @extend .fa-ul;

      li {

        .fa-li {

          line-height: 1.3;
          left: -0.9em;
          width: auto;

          .fa-square {

            color: $puntielenco;
            @include responsive-font-size($list-font-size);

          }

        }

      }

    }

  }

  %OLdefinition {

    @extend .fa-ul;
    margin-left: 1em;
    counter-reset: li;

    > li {

      counter-increment: li;
      @extend .d-block;
      // @extend .d-flex;
      // @extend .flex-column;

      &:before {
        color: $puntielenco;
        content: counter(li)".";
        margin-left: -1em;
        @extend .position-absolute;
        @extend .semibold;
      }

    }

  }

// spazi basic ELEMENTI

  %basicElementSpaceLast { // dentro accordion, field, footer pinforma

    h4, p, dd, blockquote, ul, ol, dl { // dentro field, footer pinforma

      @extend .mb-3;

      &:last-child {

        @extend .mb-0;

      }

    }

  }

  %basicElementSpaceFirst { // dentro accordion

    h4, p, dd, blockquote, ul, ol, dl {

      @extend .mb-3;

      &:first-child {

        @extend .mt-3;

      }

    }

  }

// definizione stile open/close su after

  %accordion-open {

    @extend .fas;
    content:"\f00d";

  }

  %accordion-close {

    @extend .fas;
    content:"\f078";

  }
