//main: style.scss
// stili header e footer che portiamo esternamente

/* header
--------------------------------------------------*/

  #header {

    background-color: $bianco;

    &.header-nomenu { // per la versione statica o esterni

      @include box-shadow-my(0, 6px, 15px, 0, $bordo-menutendone );

    }

    #navbar-top {

      @extend .px-0;

      .region-top-header {

        @extend .row;
        @extend .no-gutters;

        .block-istituzione {

          @extend .col;
          @extend .no-gutters-col;
          border-bottom: 1px solid $beige;

          p {

            @extend .reset-all-space;

            span {

              @extend .sr-only;

            }

          }

          a {
            @extend .semibold;
            @include setLinkSelectorStyle {color:$black;}
            @include linkNoUnderline;
            @extend .px-0;
            @extend .px-md-0;
            @extend .py-1;
            @extend .py-md-2;
            @extend .d-inline-block;
          }

        }

      }

    } //  #navbar-top

    #header-main {  //#navbar-main

      // @extend .py-5;
      padding-top: 8px;
      padding-bottom:8px;
      @extend .py-md-6;

      .container {

        .row {
          @extend .no-gutters;
          @extend .align-items-center;
        }

        .region-header,
        .region-header-form,
        .region-header-menu-mobile {
          @extend .no-gutters-col;
        }

        // LOGO
        .region-header {
          @extend .col-4;
          @extend .col-md-4;

          h1 {

            @extend .m-0;
            @extend .p-0;

          }

          a {


            display: inherit;
            @extend .reset-all-space;

            img {

              @extend %transition-standard;

              max-width:100%;
              height: 100%;

              @include media-breakpoint-up(lg) {

                max-width: 275px;

              }

            }

          }

        }

        // ricerca
        .region-header-form {

          @extend .col-7;
          @extend .col-lg-8;
          @extend .d-flex;
          @extend .flex-row;
          @extend .flex-lg-column;
          @extend .flex-wrap;
          @extend .justify-content-end;
          @extend .ml-auto;
          //@extend .align-items-right;

          // social
          .blocco-socialrp {

            @extend .pb-lg-2;
            @extend .d-lg-flex;
            // @extend .d-none;

            a {

              @include setLinkSelectorStyle {color:$black;}

              @include linkNoUnderline;  // x ovviaviare un problema di validazione che implica l'inserimento del underline...

            }

          }

          .search-block-form {

            @extend .d-flex;
            @extend .flex-md-row;
            @extend .justify-content-end;
            @extend .align-items-center;
            @extend .align-self-end;
            align-self:inherit!important;

            width:33%;

            @include media-breakpoint-up(lg) {

              width:100%; // x firefox inferiori

            }

            .content {
              @extend .col-12;
              @extend .col-lg-5;
              @extend .reset-all-space;
              @extend .text-right;

              .content {
                @extend .col-12;
                @extend .reset-all-space;

                form.search-block-form {
                  @extend .d-none;
                  @extend .d-lg-inline-flex;
                }

                .icon-search {
                  @extend .d-inline-block;
                  @extend .d-lg-none;
                  @extend .p-0;
                  width: 44px;
                  @extend .text-center;
                  @include setLinkSelectorStyle {color:$black;}
                  font-size: $button-search-font;

                  span {
                    @extend .d-inline-block;
                    @extend .p-0;
                  }

                }

              }

            }

          }

        }

      } // container

      /* form di ricerca
      --------------------------------------------------*/

      form.search-block-form {

        @extend .d-lg-none;
        @extend .reset-all-space;
        @extend .border;
        border-color: $grigio-light!important;
        background-color: $white;
        @extend .justify-content-end;
        width:100%;

        &.form-row {
          .form-actions {
            align-self: auto;
          }
        }

        fieldset {
          @extend .col-11;
          @extend .col-lg-10;
          @extend .reset-all-space;

          label {
            @extend .position-absolute;
            left: 1rem;
            top: 0.3rem;
            z-index: 1;
            @extend .mb-0;
            color: $grigio-light!important;
            text-transform: lowercase;

            @include media-breakpoint-down(md) {
              top: 0.5rem;
            }

          }

          input:required {
            z-index: 2;
          }

          input:required:invalid {
             z-index: 0;
          }

          .form-search {

            @extend .border-0;
            height: 100%;
            @extend .position-relative;

            &:focus, &:focus:required:invalid {

              z-index: 2;

            }

          }

        }

        .form-actions {
          @extend .col-1;
          @extend .col-lg-2;
          @extend .p-0;
          @extend .mb-0;
          @extend .text-right;

          button {
            width: 100%;
            height:100%;
            @extend .fas;
            @extend .fa-search;
            @extend .btn-clear;
            @extend .bkg-none;
            @extend .border-0;
            font-size: $button-search-font-small;
            @extend .p-2;
            color: $black;

            // &:hover, &:focus, &:active,
            // &:not(:disabled):not(.disabled):active,
            // &:not(:disabled):not(.disabled).active,
            // &:not(:disabled):not(.disabled):active:focus,
            // &:not(:disabled):not(.disabled).active:focus{
            //   @extend .bkg-none;
            //   @extend .border-0;
            //   @extend %box-shadow-focus-none;
            // }

          }

          span {
            @extend .sr-only;
          }

        }

      }

    } //hedare-main

  } //#header

/* Back to Top
--------------------------------------------------*/

  #back-to-top {
    display: none;
    @extend .position-fixed;
    right: 2rem;
    top: auto;
    bottom: 2rem;
    z-index: 1;

    button {
      font-weight: 900!important; // non vince sul .btn, lo forzo
      @extend .fas;
      @extend .btn;
      @extend .btn-primary;
      @extend .btn-light;
      @extend .p-0;
      @extend .fa-chevron-up;
      @include responsive-font-size($accordion-icon-font-size);
      width: 3rem;
      height: 3rem;
      border: 3px solid $rosso;
      color: $rosso;

      &:hover, &:focus {

        border: 3px solid $rosso;
        color: $rosso;

      }

      &:not(:disabled):not(.disabled) {
          cursor: pointer;
      }

      em {
        span {
          @extend .sr-only;
        }
      }

    }

  } //#back-to-top


/* Footer
--------------------------------------------------*/

  .site-footer {

    background-color: $grigio;
    color: $bianco;
    @extend %space-top-base-content-padding;
    @extend %space-bottom-base-content-padding;
    font-family: $font-family-base;

    &.footer-nomenu { // only statiche

      .region-footer-fifth { // AREA contatti

        &:before {

          @extend .d-none;

        }

        &:after {

          @extend .d-none;

        }

      }

    }

    a {

      @include setLinkSelectorStyle {color:$bianco;}
      @include linkNoUnderline;

    }

    a[target="_blank"]:after {

      color: $bianco;

    }

    .content {

      @include responsive-font-size($font-size-standard);

    }

    .content, h2 {

      color: $bianco;

      a {

        @include setLinkSelectorStyle {color:$bianco;}
        @include linkNoUnderline;

      }

      ol:not(.menu),
      ul:not(.menu) {

        padding-left: 0;

      }

    }

    .block {

      @extend .my-0;

    }

    aside.container {

      @include backgroundShorthandWithExternalVar('logo_RP_footer.png',$position:15px 0,$repeat: no-repeat, $color:transparent);
      padding-top:66px;

      .site-footer__top { // i tre menu centrali

        @extend .row;
        @extend %space-top-base-content-padding;
        @extend %space-bottom-base-content-padding;

        .region {

          @extend .col;

          &:first-child {

            .block {

              .content, &.block-menu {

                .nav { //ul

                  > li {

                    > a, > span {

                      @extend .pt-0;

                    }

                  }

                }

              }

            }

          }

          .block {

            @extend .p-0;
            @extend .m-0;
            border: 0;

            .content, &.block-menu { // i menu area tematica e Contenuti ereditano l'html del menu principale, quelli custom non hanno il div 'content'

              @extend .pt-0;
              // border:1px solid violet;

              .nav { //ul

                > li {

                  > a, > span {

                    @include responsive-font-size($menufooter-title-font);
                    @extend .semibold;
                    @extend .pt-6;
                    @extend .pt-lg-0;

                  }

                  li {

                    &:last-child {

                      a {

                        @extend .pb-0;

                      }

                    }

                  }

                }

                li {

                  // border:1px solid red;

                  a, span {

                    border:0;
                    line-height:1.6;
                    @extend .px-0;
                    @extend .pt-0;
                    @extend .pb-3;
                    @extend .m-0;
                    text-indent:0;

                  }

                  a {

                    &:hover, &:focus {

                      @include linkNoUnderline;
                      background-color: transparent

                    }

                  }

                }

              }

            }

          }

        }

        .region-footer-first,
        .region-footer-second,
        .region-footer-third {

          @extend .col-12;
          @extend .col-lg-4;

        }

      }

      .site-footer__bottom { // contatti e menu a pié di pagina

        letter-spacing: 0;
        border: 0;
        @extend .mt-0;

        .region {

          @extend .row;

          .block-contatti {

            // border:1px solid green;
            h2 {

              @include responsive-font-size($menufooter-title-font);
              @extend .semibold;
              @extend .mb-6;

            }

            @extend .col-12;
            @extend .col-lg-8;

            .field {

              @extend .row;

              .contact-1{

                @extend .col-12;
                @extend .col-lg-12;
                @extend .col-xl-4;
                @extend .pt-5;
                @extend .pt-xl-0;

              }

              .contact-2,
              .contact-3 {

                @extend .col-12;
                @extend .col-lg-6;
                @extend .col-xl-4;
                @extend .pt-5;
                @extend .pt-xl-0;

              }
              .contact-1,
              .contact-2,
              .contact-3 {

                // @extend .col-12;
                // @extend .col-md-4;
                // @extend .pt-5;
                // @extend .pt-lg-0;

                &:first-child {

                  @extend .pt-0;

                }

                p {

                  &:last-child {

                    @extend .mb-0;

                  }

                }

              }

            }

          }

          .blocco-socialrp-footer {

            // border:1px solid red;
            @extend .col-12;
            @extend .col-lg-4;
            @extend .pt-6;
            @extend .pt-lg-7;

            .field {

              @extend .justify-content-start;
              @extend .justify-content-lg-end;

              ul {

                li {

                  a {

                    @include linkNoUnderline;  // x ovviaviare un problema di validazione che implica l'inserimento del underline...

                    &[target="_blank"]:before {

                      color:$bianco;

                    }

                    em {

                      color:#000

                    }

                  }

                }

              }

            }

          }

          .menu--menu-footer {

            @extend %space-top-base-content-padding;

            @extend .col-12;
            @extend .col-lg-12;

            .nav {

              @extend .d-lg-flex;
              @include responsive-font-size($unit-default-px);

              .nav-item {

                @extend .position-relative;
                @extend .mb-0;
                @extend .mb-lg-1;

                a {

                  @extend .py-3;
                  @extend .py-lg-0;
                  @extend .pl-0;
                  @extend .pl-lg-3;
                  @extend .pr-0;
                  @extend .pr-lg-3;
                  line-height: 1.2rem;

                }

                &:first-child {

                  a {

                    @extend .pl-lg-0;

                  }

                }

                &:last-child {

                  &:after {

                    @extend .d-none;

                  }

                }

                @include media-breakpoint-up(lg) {

                  &:after {

                    content:" ";
                    background-color: $bianco;
                    width:1px;
                    height:100%;
                    position: absolute;
                    right: 0;
                    top:0;

                  }

                }

              }

            }

          }

        }

      }

    }

    @include media-breakpoint-up(lg) {

      .region-footer-fifth { // AREA contatti

        position:relative;
        overflow: hidden;
        @extend %space-top-base-content;

        &:before {

          content:" ";
          position: absolute;
          top:2.5rem;
          border-top:2px solid $grigio-medio;
          left:15px;
          width: 100%;

        }

        &:after {

          content:" ";
          position: absolute;
          top:2.5rem;
          border-top:2px solid $grigio;
          right:0;
          width: 15px;

        }

      }

    }

  }
