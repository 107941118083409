// compileCompressed: ../css/$1-small.css

  // x statiche  --  compileExpanded x estrarre fontawesome
  // compileCompressed: ../css/$1-small.css
  // x portale
  // compileCompressed: ../css/$1.css

  // Theme Name: Bootstrap 4 Sass for Regione Piemonte

  // list of import files

//@import "import";
@import "import-small";
