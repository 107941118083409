//main: style.scss

/* Filo d'arianna
--------------------------------------------------*/

  .block-system-breadcrumb-block {

    @extend %transition-standard;
    height: 43px; // obbligatorio per transition!!

    .content {

      min-height: 43px;
      display: block;

    }

    p {

      @extend .sr-only;

    }

    &.breadcrumb-hide {

      height: 0;
      @extend .p-0;
      @extend .m-0;
      border-bottom:0;
      opacity:0;

      .content {

        min-height:0;

      }

      .breadcrumb {

        opacity:0;

      }

    }

    .breadcrumb {

      @extend .bkg-none;
      color:$black;
      border-bottom: 1px solid $black;
      border-radius: 0;
      @extend .mb-0;
      @extend .px-0;
      @extend .pt-2;
      @extend .pb-2;
      min-height: 0;
      line-height: 1rem;

      .breadcrumb-item {

        @extend .d-none;
        @extend .d-md-block;

        &.active {
          color: $black;
        }

        &:first-child {

          a {

            @extend .fas;
            @extend .fa-home;
            white-space: nowrap;
            overflow: hidden;
            text-indent: -10000000px;
            min-width:20px;
            line-height: 1rem;

            &:before {

              text-indent: 0;
              float: left;

            }

          }

        }

        &:first-child,
        &:last-child {

          @extend .d-block;
          @extend .d-md-block;

        }

        a {
          color: $black;
          @include linkNoUnderline;
          @include responsive-font-size($p-font-size);
        }

      }

      .breadcrumb-item + .breadcrumb-item:before {

        color: $black;
        @extend .pr-0;
        @extend .pr-md-1;
        @extend .pl-md-1;

        @include media-breakpoint-up(sm) {

          content: "/";

        }

        @include media-breakpoint-down(sm) {

          content: "... /";

        }

      }

    }

  }
