//main: style.scss

/* TYPOGRAPHY */
// calcolare i rem dai px: XXpx / 16px = XX rem

// font families
$font-titillium: 'Titillium Web',
Arial,
Verdana,
sans-serif;

$font-family-base: $font-titillium;

// font size
// INTRODURLO CON @include responsive-font-size($p-font-size);
$rfs-breakpoint: 1440;
$unit-default-px: 16px; // for mixin rem/px units
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-standard:18px;
$font-size-minimo:12px;
$h1-banner-font-size:70px;
$h1-font-size: 54px;
$h2-font-size: 40px;
$h3-font-size: 32px;
$h4-font-size: 23px;
$h5-font-size: 21px;
$h6-font-size: $font-size-standard;
$p-font-size: $font-size-standard; // anche la citazione
$font-size-small: $font-size-base;
$font-size-extra-small: $font-size-minimo;

//box servizonline
$slogan-srp: 20px;

// meteo
$meteo-data-font-size: $h5-font-size;

// live streaming
$live-font-size: $h5-font-size;
$live-icon-font-size: 26px;

// icon list
$list-font-size: 8px;

//accedi al servizioSP
$SP-font-size: $font-size-standard;

// smistamento
$card-smistamento-title-font-size: $h3-font-size;

// card
$card-focus-title-font-size:$h4-font-size;
$card-title-font-size: $h5-font-size; // $h4-font-size;
$card-icon-font-size: $h3-font-size;
$card-size-small:14px;
$card-size-small-more:16px;

// HOMEPAGE
$card-title-news-lancio-1: 50px;
$card-title-news-lancio-all: $h5-font-size;

// twitter title
$twitter-title-font-size:30px;

// icone download
$icon-font-size: $h4-font-size;

// Contenuti del Banner del tema
$paragrafo-tema-font: $h3-font-size;
$button-tema-font: $font-size-standard;

// form di ricerca trasversale
$button-search-font: $h3-font-size;
$button-search-font-small: $h5-font-size;

// msg
$msg-high: $h3-font-size;

// menu orizzontale
$firstlevel-menu-trasv-font:22px;
$subtitlemenu-menu-trasv-font: $h3-font-size;
$subtitlelist-menu-trasv-font: $font-size-standard;
$menu-trasv-font-minimo:15px;

// menu dei temi sidebar
$menutema-tema-font:23px;
$menutema-tema-mobile-font:28px;
$menutema-tema-font-small:$font-size-small;

// menu footer 3col
$menufooter-title-font:25px;

// accordion
$accordion-icon-font-size-new: 20px; //icon
$accordion-icon-font-size: 30px; //icon
$accordion-title-font-size: $h5-font-size;
$accordion-titlebig-font-size: $h3-font-size; //competenze
$accordion-v-font-size: 21px; // icon accordion a 'v'

// accordion speciale ricerca
$accordion-font-size-search: 20px;

// font weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
