//main: style.scss
// IMPORTS

// import to font Awesome 6.4.2
// -----------------------------------------------------------------------------
$fa-font-path:  "../lib/fontawesome-free-6.4.2-web/webfonts" !default;
@import "../lib/fontawesome-free-6.4.2-web/scss/v4-shims.scss";
@import "../lib/fontawesome-free-6.4.2-web/scss/fontawesome.scss";
@import "../lib/fontawesome-free-6.4.2-web/scss/solid.scss";
@import "../lib/fontawesome-free-6.4.2-web/scss/regular.scss";
@import "../lib/fontawesome-free-6.4.2-web/scss/brands.scss";
@import "../lib/fontawesome-free-6.4.2-web/scss/screen-reader.scss";

/* import to rfs (Responsive Font Size) */
@import "../lib/rfs-master/scss/rfs.scss";
/* import to my definition */
@import "variables";
@import "typography";
/* import to bootstrap */
@import "../lib/bootstrap/scss/functions";
@import "../lib/bootstrap/scss/variables";
//continue bootstrap
@import "../lib/bootstrap/scss/mixins";
/* OK basic start style */
@import "../lib/bootstrap/scss/root";
@import "../lib/bootstrap/scss/reboot";
@import "../lib/bootstrap/scss/type";
// @import "../lib/bootstrap/scss/images";
// @import "../lib/bootstrap/scss/code";
@import "../lib/bootstrap/scss/grid";
@import "../lib/bootstrap/scss/tables";
@import "../lib/bootstrap/scss/forms";
@import "../lib/bootstrap/scss/buttons";
// @import "../lib/bootstrap/scss/transitions";
// @import "../lib/bootstrap/scss/dropdown";
// @import "../lib/bootstrap/scss/button-group";
// @import "../lib/bootstrap/scss/input-group";
// @import "../lib/bootstrap/scss/custom-forms";
@import "../lib/bootstrap/scss/nav";
@import "../lib/bootstrap/scss/navbar";
// @import "../lib/bootstrap/scss/card";
@import "../lib/bootstrap/scss/breadcrumb";
// @import "../lib/bootstrap/scss/pagination";
// @import "../lib/bootstrap/scss/badge";
// @import "../lib/bootstrap/scss/jumbotron";
// @import "../lib/bootstrap/scss/alert";
// @import "../lib/bootstrap/scss/progress";
@import "../lib/bootstrap/scss/media";
// @import "../lib/bootstrap/scss/list-group";
// @import "../lib/bootstrap/scss/close";
// @import "../lib/bootstrap/scss/modal";
// @import "../lib/bootstrap/scss/tooltip";
// @import "../lib/bootstrap/scss/popover";
// @import "../lib/bootstrap/scss/carousel";
@import "../lib/bootstrap/scss/utilities";
@import "../lib/bootstrap/scss/print";
/* OK basic end style */
// barrio
//main: style.scss
/* OK basic start style */
//@import "./components/affix";
/* OK basic end style */
// @import "./components/alerts";
// @import "./components/book";
// @import "./components/book-navigation";
@import "./components/breadcrumb";
// @import "./components/comments";
// @import "./components/contextual";
// @import "./components/details";
// @import "./components/dropbutton.component";
// @import "./components/dropbutton";
// @import "./components/feed-icon";
// @import "./components/field";
// @import "./components/file";
// @import "./components/form";
// @import "./components/header";
// @import "./components/help";
// @import "./components/icons";
// @import "./components/image-button";
// @import "./components/indented";
// @import "./components/item-list";
// @import "./components/list-group";
// @import "./components/node";
// @import "./components/node-preview";
// @import "./components/page";
// @import "./components/search-form";
// @import "./components/shortcut";
// @import "./components/sidebar";
// @import "./components/site-footer";
@import "./components/skip-link";
// @import "./components/table";
// @import "./components/tabledrag";
// @import "./components/tableselect";
// @import "./components/tablesort";
// @import "./components/tablesort-indicator";
// @import "./components/tabs";
// @import "./components/textarea";
// @import "./components/ui-widget";
// @import "./components/ui-dialog";
// @import "./components/user";
// @import "./components/vertical-tabs.component";
// @import "./components/vertical-tabs";
// @import "./components/views";

//my mixin (li tengo qui per far prendere variabili del button)
@import "mixins"; // anche per le statiche
// @import "mixins-portal"; // minxin che includono fontawesome e specifiche

//my micro sass (solo quelli che mi servono)
@import "breadcrumbs";

/* OK basic start style */
@import "header-footer";
@import "style-basic";
// @import "style-portal";
/* OK basic end style */


// copio qui i soli stili che mi servono di
// web/core/modules/system/css/components/hidden.module.css

.visually-hidden {
    @extend .position-absolute;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;

    &.focusable:active,
    &.focusable:focus {
      @extend .position-static;
      clip: auto;
      overflow: visible;
      height: auto;
      width: auto;
    }

}

// SPECIALE statiche
body .main-content article.node.node--view-mode-full .node__content .field.text-formatted ol,
body .main-content article.node.node--view-mode-full .node__content .field.text-formatted ul {

  @extend .pl-0;

}

//  PER TESTARE SU TEMPLATE STATICI FULL HTML RP
//
// .primary_menu {
//
//   display:none!important;
//
// }
//
// .responsive-menu-toggle {
//
//   display:none!important;
//
// }
