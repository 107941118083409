//main: style.scss

/* VARIABLES */

$md-gutter:30px; // lo aggiungo x row-hack;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// md: 768px, // al di sotto di questo variabile spazi inferiori
// lg: 992px  // al di sotto burger menu
// xl: 1440px // al di sotto di questo ridimensiona il testo

// usage @include media-breakpoint-up(lg) { }

$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1440px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
sm: 540px,
md: 720px,
lg: 960px,
xl: 1140px,
xxl: 1380px
);

/* COLOR RP */

$color-pi:     #fde760;

// PRIMARI //
$rosso:        #b8191a;
$rosso-medio:  #c6474a;
$rosso-light:  #da8b8d;
$blu:          #124072;
$blu-medio:    #44698d;
$blu-light:    #8aa0b9;
$focus:        #ff8c00;

// colori accessibili font più piccoli
$rosso-acc:    #AD191A; //A1191A;


// SFONDI //
$beige:         #e3e0de;
$beige-medio:   #e9e7e6;
$beige-light:   #f1f0ef;
$puffo:         #546b78;
$puffo-medio:   #788891;
$puffo-light:   #aab5ba;
$grigio:        #38454d;
$grigio-medio:  #606a70;
$grigio-light:  #9ba2a6;
$bianco:        #fff;
// extra non indicati nelle linee guida
$border-color:#d9d9d9;

// ALERT //
$bkg-color-error:#F1DDDE;
$border-color-error:#E2352E;
$bkg-color-success:#DFF1D9;
$border-color-success:#5CB875;
$bkg-color-info: #DAEEF6;
$border-color-info:#01B5EA;
$bkg-color-warning: #FDFAE7;
$border-color-warning: #FFF057;
//msg allerta boschi
$allerta-neutrale: #606a70;
$allerta-attenzione: #feb711;
$allerta-massima:#E2352E;
// $allerta-fase-attesa:
$allerta-normale:  #606a70; //#01B5EA;
$allerta-fase-attenzione: #A5E5DD; //#feb711;
$allerta-fase-preallarme: #CC99FF; //#cd99fe;
$allerta-fase-allarme: #660066;
// allerta box
$allerta-box:$beige-light;

// colors
$primary-shade: #A8CF45;
$primary-light: lighten($primary-shade, 37%);
$primary-dark: darken($primary-shade, 12%);
$accent-shade: #0079C0;
$accent-light: lighten($accent-shade, 37%);
$accent-dark: darken($accent-shade, 12%);
// Reassign color vars to semantic color scheme
$red: #dc3545;
$yellow: #ffc107;
$green: #28a745;
$cyan: #17a2b8;
$gray-100: #f8f9fa !default;
$gray-800: #343a40 !default;
$theme-colors: ( primary: $blu, secondary: $bianco, success: $green, info: $blu, warning: $beige, danger: $rosso, light: $gray-100, dark: $gray-800, clear: #fff);
$brand-primary: $rosso;
$brand-success: $bkg-color-success;
$brand-info: $bkg-color-info;
$brand-warning: $bkg-color-warning;
$brand-danger: $bkg-color-error;
//$brand-inverse: $primary-shade;
// Body
// Settings for the `<body>` element.
//$body-bg: $white;
//$body-color: $gray-dark;
//$inverse-bg: $primary-shade;
//$inverse-color: $gray-lighter;

// colori stato Pre informazione fondi UE
$color-stato-Pre-informazione: #0071E1;
$color-stato-Attuato: #00963f;
$color-stato-Concluso: #e2290d;

//colori stato bandi
$color-stato-Aperto: #00963f;
$color-stato-Scaduto: #e2290d;
$color-stato-Esito: #feed00;
$color-stato-Annullato: #ee7218;
$color-stato-Sospeso: #ffff00;
$color-stato-Riaperto: #0c964d;
$color-stato-Prorogato: #FA7268;

// new accordion
$new-grigio:#E2E0DE;
$new-rosso:#A92C25;

// Links
// Style anchor elements.
$link-color: $rosso-acc;
$link-decoration: underline;
$link-hover-color: $rosso-acc;
$link-hover-decoration: none;
// Comments
$comment-spacer-x: 1.25em;
$comment-spacer-y: 1.25em;
//definizione per elementi
$puntielenco:$rosso;
$bordo-menutendone:rgba(0,0,0,0.3);
$sfondo-menup:$beige-light;
// definizion x cardRp
$gradient_Grey: #c7c7c7;
//card senza immagini
$cardGradient: $gradient_Grey;
$cardBorder: $border-color;
//card per entry point
$cardServiziColor: $blu;
// card per sezioni in evidenza
$cardEvidenzaColor: $brand-primary;
// card per Schede Informative
$cardSchedeInfoColor: $beige-light;
// card per linee
$cardModulisticaLinea: $blu-light;
$cardNormativaLinea: $rosso-light;
//card box
$cardRP: $cardBorder;
$card-min-height:180px;
$card-min-height-small:150px;
$card-min-height-min:90px;
$card-min-height-im:500px;
// card striscia
$striscia-card: $beige;
// focus state
$keyFocus: $focus;
$keyThinFocus: $focus;
// sfondi speciali
$evidenceBkg:$beige;
$blockquoteBkg:transparent;
$blockquoteVirgole:$rosso;
$abstractFiletto:$beige-medio;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
0: 0,
1: ($spacer * .375),    // 6px
2: ($spacer * .75),     // 12px
3: $spacer,             // 16px
4: ($spacer * 1.125),   // 18px
5: ($spacer * 1.25),    // 20px
6: ($spacer * 1.875),   // 30px
7: ($spacer * 3.75),    // 60px
8: ($spacer * 5.625),   // 90px
9: ($spacer * 4.375),   // 70px

// special  %space-top-base-title-text-formatted
10: ($spacer * .25),   // 4px
11: ($spacer * .875),   // 14px

// special alert icon space
12: ($spacer * 4.5),
13: ($spacer * 9),

15: ($spacer * .9375), // 15px

// speciali
16: ($spacer * 2),      // 32px (non usato ex.accordion)
17: ($spacer * 2.75),   // 44px (x arrivare a 60 da 16 e spazi speciali)
18: ($spacer * .55),    // 8px (streaming live hp)

19: ($spacer * 3.125)    // 50px (x menu sidebar PI homepage)

), $spacers);
