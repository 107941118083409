//main: style.scss

/* GENERAL STYLES
--------------------------------------------------*/

  body {

    a {

      @include linkFocusAcc;

    }

    // valgono anche per FCK-Editor ---||| body.cke_editable
    &.cke_editable,
    .layout-main-wrapper {

      a[target="_blank"] {
  
        &:after {
  
          @extend .fas;
          content:"\f35d";
          @extend .pl-1;
          color:$black;
  
        }
  
      }

    }

    background: $white;
    color: $black;
    @include responsive-font-size($p-font-size);
    font-family: $font-family-base;

    select::-ms-expand { // IE10
      display: none;
    }

    @-moz-document url-prefix() {

      // soluzione presa da:
      // https://gist.github.com/joaocunha/6273016

      .select-wrapper {

        @include media-breakpoint-down(md) {

          .select-container {

            width:90%;
            text-align: center;

          }

        }

        .select-container {

          overflow: hidden;

        }

        select {

          width: 111%!important;
          min-width: 250px;

          &.custom-select {

            background-position: right 4rem center;

          }

        }

      }

    } // @moz-document

    select {

      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance:    none;
      appearance:         none;
      padding: 0 30px 0 10px!important;
      @extend .custom-select;

    }

    input[type="text"] {

      @extend .form-control;

    }

    // button, input,
    // button:focus,
    // input:focus,
    // a:focus {
    //   border-radius:0!important;
    // }

  	// :focus {
    //   @extend %focus-none;
    // }

    .keyFocus:not(.category-onoff) {
      @extend %focus-visible;
    }

    .keyFocusNone:not(.category-onoff) {
      @extend %focus-none;
    }

    .keyFocusIn:not(.category-onoff) {
      @extend %focus-visible;
    }

    .form-control:focus {

      border:1px solid $keyThinFocus;

    }

    .main-content {

      img {

        @extend %imgFitCard;

      }

      article {

        &.node {

          .in-evidenza {

            .ckeditor-accordion-container {

              &:first-of-type {

                border-top: 0!important;
    
              }

              dl {
  
                dt {

                  &.active {

                    a {
  
                      &.ckeditor-accordion-toggler {
  
                        border-bottom: 0px solid $evidenceBkg;
                            
                        &:active, &:visited, &:hover, &:focus {
    
                          border-color: $evidenceBkg;
    
                        }

                        &:after {
      
                          color:$new-grigio;
                          background-color: $new-rosso;
        
                        }

                      }
  
                    }
  
                  }
  
                  a {

                    &.ckeditor-accordion-toggler {
                   
                      border-bottom: 0px solid $evidenceBkg;
        
                      &:active,
                      &:hover,
                      &:focus {
  
                        border-color: $evidenceBkg;

                      }
        
                      &:after {
        
                        color:$new-rosso;
                        background-color: $white;
      
                      }
  
                    }
      
                  }  

                }

              }

            }

          }

          .ckeditor-accordion-container {

            @extend .mb-3;
            border-top: 1px solid $new-grigio!important;
            // &:first-of-type NUUU

            + .ckeditor-accordion-container {

              border-top: 0 !important;
    
             }
    

            dl {

              border: 0;

              dt {

                @include responsive-font-size($accordion-title-font-size);

                .ckeditor-accordion-toggle {

                  display: none;

                }

                &.active {

                  a {

                    &.ckeditor-accordion-toggler {

                      color: $black;
                      @extend .bkg-none;
                      border-bottom: 1px solid $bianco;

                      &:active,
                      &:visited,
                      &:hover,
                      &:focus {
      
                        color: $black;
                        @extend .bkg-none;
                        border-color: $bianco;
      
                      }
      
                      &:after {
      
                        //@extend .fa-minus;
                        content: "\f106";
                        color:$new-grigio;
                        background-color: $new-rosso;

      
                      }

                    }
    
                  }
    
                }
    
                a {

                  &.ckeditor-accordion-toggler {

                    @extend %transition-standard;
                    color: $black;
                    @extend .bkg-none;
                    @extend .p-3;
                    @extend .pr-17;
                    @extend %icon-center-right;
                    border-bottom: 1px solid $new-grigio;
                    text-decoration: none;
      
                    &:active,
                    &:hover,
                    &:focus {

                      border-color: $new-grigio;

                      &:after {
  
                        color:$new-grigio;
                        background-color: $new-rosso;
                        @extend .pb-2;
  
                      }

                    }
      
                    &:after {
      
                      @extend .fas;
                      content: "\f107";
                      @include responsive-font-size($accordion-icon-font-size-new);
                      @extend .p-2;
                      border-radius:50%;
                      color:$new-rosso;
                      background-color: $new-grigio;
                      // aggiunte per versione statioca che non fa larghezza rretta
                      width: 44px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
    
                    }

                  }
    
                }

              } //dt

              dd {

                @extend .mb-0;
                border-bottom: 1px solid $new-grigio;

                @extend %basicElementSpaceFirst;

              }

              &.field--name-field-materie { // dl

                @extend .border-0;

                dt {

                  @include responsive-font-size($accordion-titlebig-font-size);

                  // &.active {

                  //   a {

                  //     color: $black;
                  //     @extend .bg-transparent;
    
                  //     &:hover,
                  //     &:focus {
    
                  //       color: $black;
                  //       @extend .bg-transparent;
    
                  //     }
    
                  //     &:after {
    
                  //       content: "\f106";
    
                  //     }
    
                  //   }

                  // }

                  a {

                    &.ckeditor-accordion-toggler {

                      @extend .border-0;
                      @extend .p-0;
                      @extend .pr-6;
                      width: fit-content;
                      padding-right: 50px !important;

                      // &:after {

                      //   content: "\f107";
                      //   @extend .pr-0;
                      //   @include responsive-font-size($accordion-v-font-size);

                      // }

                    }

                  }

                } //dt

                dd {

                  @extend .border-0;
                  @extend .px-0;

                }

              }

            }

          }

          &.node--view-mode-full {

            .node__content {

              @extend .m-0;

              h4, p, ul, ol, dl {
                line-height:1.6;
              }

              @include responsive-font-size($p-font-size);

              .field {

                &.field--type-text-long,
                &.text-formatted {

                  @extend %space-content-corpo;

                }

              }


            }

          }

        }

      }

      .field { // generalizzo il formato dei contenuti "testuali" su field x evitare in altre parti

        a:not(.btn) {

          @include linkUnderline;
          @extend .bold;

          &:hover, &:focus {
            color:$white;
            background-color: $rosso;
          }

          // &.btn-secondary {
          //
          //   &:hover, &:focus {
          //     background-color: $blu;
          //   }
          //
          // }

          &[target="_blank"] {

            &:hover, &:focus {

              &:after {

                color:$white;

              }

            }

          }

        }

        &.field--label-inline {

          dd {

            @extend .mb-0;

          }

        }

        @extend %basicElementSpaceLast;

      }

    }

    &.statiche-rp {

      .main-content {

        article {

          &.node {

            .ckeditor-accordion-container {

              @extend .mb-3;

            }

          }

        }

      }

    }

  } //body

  h1, .h1 { @include responsive-font-size($h1-font-size); @extend .bold; @extend %break-word;}
  h2, .h2 { @include responsive-font-size($h2-font-size); @extend .bold; @extend %break-word;}
  h3, .h3 { @include responsive-font-size($h3-font-size); @extend .bold; @extend %break-word;}
  h4, .h4 { @include responsive-font-size($h4-font-size); @extend .bold; @extend %break-word;}
  h5, .h5 { @include responsive-font-size($h5-font-size); @extend %break-word;}
  h6, .h6 { @include responsive-font-size($h6-font-size); @extend %break-word;}

  h1 { // questo definito generico in style-base e non dentro a #block-regionepiemonte-page-title

    @extend %space-bottom-base-title;

  }


/* generic menu
--------------------------------------------------*/

  .nav {

    @extend .d-block;

    a {
      @include linkNoUnderline;
      color:$black;
    }

    ul {
      list-style: none;
      @extend .reset-all-space;
    }

  }


/* Icone social
--------------------------------------------------*/

  .blocco-socialrp {

    @extend %linkOut-noIcon;
    @extend .align-items-center;
    @extend .justify-content-end;
    width: 100%;

    .field {

      @extend .d-flex;
      @extend .flex-row;
      @extend .justify-content-end;
      @extend .align-items-center;

      p {

        @extend .semibold;
        @extend .pr-2;

        @include media-breakpoint-down(md) {

          display:none;

        }

      }

      ul {

        @extend .list-inline;

        li {

          @extend .list-inline-item;
          @extend .d-inline-flex;
          height:2rem;
          
          &:not(:last-child) {
            @extend .mr-1; // Viviana modificato marginright perchè spacca sul mobile
          }

          &.ico-rss {

            a {

              em {

                @extend .fa-rss;
                @extend .fas;

              }

            }

          }

          &.ico-twitter {

            a {

              em {

                @extend .fa-x-twitter;

              }

            }

          }

          &.ico-facebook {

            a {

              em {

                @extend .fa-facebook-f;

              }

            }

          }

          &.ico-youtube {

            a {

              em {

                @extend .fa-youtube;

              }

            }

          }

          &.ico-linkedin {

            a {

              em {

                @extend .fa-linkedin-in;

              }

            }

          }

          &.ico-flickr {

            a {

              em {

                @extend .fa-flickr;

              }

            }

          }

          a {

            @extend .p-0;
            padding-bottom:0.2rem!important;
            @extend .d-flex;
            @extend .justify-content-center;
            @extend .align-items-center;
            text-decoration: none;
            @extend .position-relative;
            @include responsive-font-size($h3-font-size);

            &:hover, &:focus {

              text-decoration: none;

            }

            &[target="_blank"]:before {

              @extend .fas;
              content:"\f111";
              @extend .position-absolute;
              z-index: 0;
              color:$black;
              top:0;

              font-size: 32px;  // new 19

              @include media-breakpoint-down(lg) {

                font-size: 30px;  // new 19

              }

            }

            &[target="_blank"]:after {

              @extend .d-none;

            }

            em {

              color: $white;
              @extend .fab;
              // @include responsive-font-size($h5-font-size); // commentato 19
              z-index: 1;
              padding-top:0px;//viviana prima era 2 // new 19

              @include media-breakpoint-up(lg) {//viviana aggiunto regola
              font-size:20px!important;//viviana prima era 20 // new 19
              padding-top:2px;//viviana prima era 2 // new 19
              }
              @include media-breakpoint-down(lg) {//viviana aggiunto regola
                font-size:15px!important;
                padding-top:0px;//viviana prima era 2 // new 19
              }


              &:before {

                display: inline-block;
                text-align: center;
                width: 30px;

                @include media-breakpoint-down(lg) {//viviana aggiunto regola
                width:22px;
                }


              }

              span {

                @extend .sr-only;

              }

            }

          }

        }

      }

      p, ul {
          @extend .reset-all-space;
      }

    }

  }

/* Generic content (dove sta il contenuto vero e proprio)
----------------------------------------------------------*/

  .layout-main-wrapper {

    @extend .pt-6;
    @extend .pt-md-7;
    @extend .pb-6;
    @extend .pb-md-7;

    #main {

      @extend .mt-0;

    }

  }
